.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Xforms custom css */
.leadforms-form-body-wrapper__20Yhy .leadforms-form-body__1cwNn {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.leadforms-form-body-wrapper__20Yhy .leadforms-form-body__1cwNn {
  padding: 0px 50px !important;
  padding-bottom: 10px !important;
  min-height: 200px !important;
}

.leadforms-input-text-inner__3M22W {
  font-family: "Avenir", sans-serif !important;
  border-radius: 10px !important;
}

.leadforms-step-instruction__2q7c4 {
  margin-bottom: 20px !important;
}

.react-tel-input .form-control {
  border-radius: 10px !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 10px 0 0 10px !important;
}

.leadforms-submit-button__2bo55,
button.leadforms-submit-button__2bo55,
input[type="submit"].leadforms-submit-button__2bo55,
button[type="submit"].leadforms-submit-button__2bo55 {
  margin: 15px auto !important;
  width: 100% !important;
}

/* Client logo crousol */
.slider {
  height: 70px;
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  overflow: hidden;
}

.slider::before,
.slider::after {
  position: absolute;
  /* background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
  content: '';
  height: 100%;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

/*  IMPORTANT CODE BELOW */

.slide-track {
  width: calc(150px * 20);
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
}

/* .slide-track2 {
  width: calc(150px * 15);
  display: flex;
  animation: scroll2 15s linear infinite;
  justify-content: space-between;
} */

.slide {
  width: 150px;
  height: 60px;

  display: grid;
  place-items: center;
  transition: 0.5s;
  cursor: pointer;
}

.slide:hover {
  transform: scale(0.8)
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(calc(-150px * 10));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(calc(-150px * 5));
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    /* width: calc(80px * 20); */
  }

  .slide-track2 {
    width: calc(80px * 15);
  }

  .slide {
    width: 80px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(calc(-80px * 10));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(calc(-80px * 5));
    }
  }
}